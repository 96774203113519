<template>
  <v-container>
    <v-row>
      <v-col v-if="!isLoggedAsDepartment" cols="3" xl="2">
        <v-card>
          <v-card-title>
            <v-toolbar-title>被试单位</v-toolbar-title>
            <v-spacer></v-spacer>
            <AppTooltipBtn
              color="primary"
              icon="mdi-domain-plus"
              tooltip="新建被试单位"
              @click="isCreatingNewDept = true"
            />
          </v-card-title>
          <v-divider></v-divider>
          <v-list nav max-height="calc(100vh - 220px)" class="overflow-y-auto">
            <v-list-item
              v-for="dept in deptList"
              :key="dept.value"
              link
              :to="getDeptDetailsRouteName(dept.value)"
            >
              {{ dept.text }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="9" xl="10">
        <router-view></router-view>
      </v-col>
    </v-row>
    <AppDialog
      v-model="isCreatingNewDept"
      title="创建新的被试单位"
      color="green"
      action-text="创建"
      :loading="isDialogLoading"
      @confirm="createOneNewDept"
      @closed="deptCreationDialogClosed"
    >
      <v-text-field
        label="被试单位名称"
        v-model="newDeptName"
        :rules="fieldRules.newDeptName"
      ></v-text-field>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { mapActions, mapGetters } from "vuex";
import { userEntityTypes } from "@/api/user";
import { createNewDept } from "@/api/dept";

export default {
  components: {
    AppTooltipBtn,
    AppDialog,
    AppMessageBox
  },

  data() {
    return {
      isDialogLoading: false,
      isCreatingNewDept: false,
      newDeptName: "",
      fieldRules: {
        newDeptName: [val => (val || "").length > 0 || "被试单位名称不能为空"]
      },
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      userEntity: "user/userEntity",
      deptList: "dept/deptList"
    }),
    isLoggedAsDepartment() {
      return this.userEntity === userEntityTypes.department;
    }
  },

  methods: {
    ...mapActions({
      refreshDeptList: "dept/refreshDeptList"
    }),
    deptCreationDialogClosed() {
      // 重置控件
      this.newDeptName = "";
    },
    getDeptDetailsRouteName(deptGuid) {
      return {
        name: "configdeptdetails",
        params: { guid: deptGuid }
      };
    },
    async createOneNewDept() {
      try {
        this.isDialogLoading = true;
        let newDeptGuid = await createNewDept(this.userGuid, {
          deptName: this.newDeptName
        });
        // 刷新列表
        await this.refreshDeptList();
        // 并且定位到新创建的dept上
        if (newDeptGuid) {
          this.$router.push(this.getDeptDetailsRouteName(newDeptGuid));
        }
        this.isCreatingNewDept = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    }
  },

  async created() {
    try {
      await this.refreshDeptList();
      if (this.deptList && this.deptList.length) {
        this.$router.push(this.getDeptDetailsRouteName(this.deptList[0].value));
      }
    } catch (err) {
      this.errorMsg = err.message;
    }
  }
};
</script>
